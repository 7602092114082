<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div class="sec-title">优惠券列表</div>
    <div class="ontents" style="margin-bottom: 1rem">
      <el-button type="warning" @click="addDialog = !addDialog" class="blt" plain size="small">生成优惠券</el-button>
      <el-button type="warning" @click=" adddialogVisible=true" class="blt" plain size="small">发放优惠券</el-button>
      <el-select v-model="couponStatus" value-key="value" placeholder="请选择" @change="changeCoupon" style="float:right;width:100px;">
          <el-option
            label="已下架"
            value="1"
          >
          </el-option>
          <el-option
            label="上架"
            value="2"
          >
          </el-option>
        </el-select>
    </div>
    <div>
      <div class="contentList">
        <div class="paging">
          <el-table
            border
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @filter-change="filterChange"
            @selection-change="selectiondrawCode"
          >
            <el-table-column
              prop="id"
              align="center"
              label="id"
            ></el-table-column>
            <el-table-column
              prop="name"
              align="center"
              label="优惠券"
            ></el-table-column>
            <el-table-column
              prop="type"
              align="center"
              label="类型"
            ></el-table-column>
            <el-table-column
              prop="cash"
              align="center"
              label="售价"
            ></el-table-column>
            <el-table-column
              prop="platform"
              align="center"
              label="平台"
            ></el-table-column>
            <el-table-column
              prop="descr"
              align="center"
              label="使用需求"
            ></el-table-column>
            <el-table-column
              prop="start_time"
              align="center"
              label="开始时间"
            ></el-table-column>
            <el-table-column
              prop="end_time"
              align="center"
              label="截止时间"
            ></el-table-column>
            <el-table-column prop="categories" align="center" label="科目">
            </el-table-column>
            <el-table-column prop="status" align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  size="small"
                  @click="openUpdate(scope.row)"
                  >修改</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  v-if="scope.row.status == 2"
                  @click="deleteCoupon(scope.row)"
                  >下架</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!--分页-->
          <paging-fy
            @currentPageChange="handleCurrentChange"
            :currentPage="currentPage"
            :total="total"
          ></paging-fy>
        </div>
      </div>
    </div>

    <div>
      <el-dialog
        :width="ks ? '90%' : ''"
        :title="addDialog ? '生成优惠券' : updateDialog ? '修改优惠券' : ''"
        :visible="updateDialog || addDialog"
        :before-close="handleClose"
      >
        <el-form :model="form">
          <el-form-item label="优惠券名称" :label-width="formLabelWidth">
            <el-input
              v-model="form.name"
              autocomplete="off"
              :placeholder="temp.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券价格" :label-width="formLabelWidth">
            <el-input v-model="form.cash" autocomplete="off" :placeholder="temp.cash"></el-input>
            最低0.1

          </el-form-item>
          <el-form-item label="优惠券描述" :label-width="formLabelWidth">
            <el-input
              v-model="form.descr"
              autocomplete="off"
              :placeholder="temp.descr"
            ></el-input>
          </el-form-item>

          <el-form-item label="课时数/领券数量" :label-width="formLabelWidth">
            <el-input v-model="form.course_num" autocomplete="off" :placeholder="temp.course_num">
            </el-input>
            单次可领最高数量
          </el-form-item>
          <!-- <el-form-item label="coupon_length" :label-width="formLabelWidth">
            <el-input v-model="form.coupon_length" autocomplete="off" :placeholder="temp.coupon_length">
            </el-input>
          </el-form-item> -->

          <el-form-item label="课时数" :label-width="formLabelWidth">
            <el-input
              v-model="form.course_num"
              autocomplete="off"
              :placeholder="temp.course_num"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="购买价格-折扣" :label-width="formLabelWidth">
            <el-input
              v-model="form.discount"
              autocomplete="off"
              :placeholder="temp.discount"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="合作商" :label-width="formLabelWidth">
            <el-checkbox v-model="if_cooperation"></el-checkbox>
          </el-form-item>
          <el-form-item label="优惠券类型" :label-width="formLabelWidth">
            <el-select
              v-model="form.type"
              remote
              @focus.once="getCouType"
              reserve-keyword
              :placeholder="couponName(temp.type).name"
            >
              <el-option
                v-for="(item, index) in cou_type_list"
                :key="index"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动开始时间" :label-width="formLabelWidth">
            <el-date-picker
              type="datetime"
              v-model="form.start_time"
              :placeholder="temp.start_time"
              value-format="yyyy-MM-dd hh:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="活动结束时间" :label-width="formLabelWidth">
            <el-date-picker
              type="datetime"
              v-model="form.end_time"
              :placeholder="temp.end_time"
              value-format="yyyy-MM-dd hh:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="有效时长" :label-width="formLabelWidth">
            <el-input v-model="form.duration" :placeholder="temp.duration"></el-input>天
          </el-form-item>
          <!-- <el-form-item label="券类目" :label-width="formLabelWidth">
            <el-select
              v-model="form.categories"
              remote
              @focus.once="getCouCate"
            >
              <el-option
                :label="item.name"
                :value="item.name"
                v-for="(item, index) in cou_cate_list"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button v-if="addDialog" type="primary" @click="add()"
            >添 加</el-button
          >
          <el-button v-if="updateDialog" type="primary" @click="update()"
            >修 改</el-button
          >
        </div>
      </el-dialog>

      <el-dialog
        title="发券"
        :visible.sync="adddialogVisible"
        :width="ks ? '90%' : '30%'"
        :before-close="handleClose"
      >
        <div>
          <el-form
            class="demo-form-inline"
            label-width="120px"
            label-position="left"
          >
            <el-form-item label="用户电话">
              <el-select
                v-model="selected_user"
                placeholder="请选择"
                value-key="user_id"
                filterable
                style="margin-top: 10px"
                remote
                :remote-method="getUser"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.user_id"
                  :label="item.nick_name"
                  :value="item.user_id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="优惠券id">
              <el-input v-model="couponid" placeholder="券id"></el-input>
            </el-form-item>

            <el-form-item label="数量">
              <el-input
                v-model="couponnum"
                placeholder="发放数量"
                type="number"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="adddialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="givecoupon">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveStreaming",
  components: {},
  data() {
    return {
      ks: 0,
      nav: {
        firstNav: "优惠券管理",
        secondNav: "优惠券列表",
      },
      addDialog: false,
      updateDialog: false,
      temp: {},
      // 、、、
      if_cooperation: false,
      tableData: [], //总数据
      code: "",
      id: [],
      total: 0,
      cou_type_list: [],
      name: "",
      phone: "",
      couponCode: "",
      sels: [], //选中数据组
      form: {
        categories: "体育运动",
        start_time: new Date().Format("yyyy-MM-dd hh:mm:ss"),
      },
      pageSize: 10,
      currentPage: 1,
      total: 0,
      cou_cate_list: [],
      formLabelWidth: '120px',
      couponid:'',
      couponnum:1,
      userList:[],
      selected_user:'',
      adddialogVisible:false,
      couponStatus:2
    }
  },
  mounted() {
    this.GetList();
    this.GetNum();
    this.getCouType();
  },
  updated() {
    this.ks = window.screen.width < 768;
  },
  methods: {
    givecoupon() {
      if (this.couponid == "") {
        this.$message({ message: "请选择券" });
        return;
      }
      if (this.couponnum == "" || this.couponnum <= 0) {
        this.$message({ message: "请输入数量" });
        return;
      }
      if (this.selected_user == "") {
        this.$message({ message: "请选择用户" });
        return;
      }

      let me = this;
      let data = {
        user_id: this.selected_user,
        source: "后台",
        rcharge_abstract: "购买消费券",
        pay_type: "",
        // rcharge_valid: val.code,
        invite_code: "",
        rcharge_type: "coupon",
        course_num: this.couponnum,
        is_teach_paypal: true,
        is_one_time_payment: true,
        studycard_id: this.couponid,
      };
      me.$axios({
        method: "POST",
        url: "/user/pay/payPre",
        data: data,
      }).then((res) => {
        this.$message({ message: res.data.message });
        this.adddialogVisible = false;
      });
    },
    getUser(query) {
      this.user_list = []
      if (query && typeof query == 'string') {
        let url = '/user/user/queryByMessage'
        this.$axios
          .get(url, {
            params: {
              login_name: query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    couponName(type) {
      let a = this.cou_type_list.find((item) => {
        return item.type == type;
      });
      if (a) {
        return a;
      } else return {};
    },
    add() {
      let _this = this
      let url = '/user/commoditycoupon/insert'
      this.form.status = 2
      this.form.categories_child = ''
      this.form.platform = this.if_cooperation ? '合作商' : ''
      this.form.coupon_length=8
      this.form.categories="体育运动"
      console.log(this.form)
      let data = {
        ...this.form,
      }
      data.duration = this.form.duration*60*24 || 86000
      this.$axios.post(url, data).then((res) => {
        console.log(res);
        this.form = {};
        this.if_cooperation = false;
        this.$message({ message: res.data.message });
        this.GetList();
      });
      this.addDialog = !this.addDialog;
    },
    openUpdate(row) {
      this.temp = row
      this.form = row
      this.form.duration = this.form.duration/60/24
      this.if_cooperation = row.platform == '合作商'
      this.updateDialog = true
    },
    update() {
      let url = "/user/commoditycoupon/update";
      this.form.id = this.temp.id;
      this.form.platform = this.if_cooperation ? "合作商" : "";
      this.form.status = 2;
      this.form.duration = this.form.duration * 60 * 24;
      console.log(this.form);
      let data = {
        ...this.form,
      };
      this.$axios.post(url, data).then((res) => {
        console.log(res);
        this.form = {};
        this.if_cooperation = false;
        this.GetList();
      });
      this.updateDialog = false;
    },
    copy() {
      let text = this.code;
      this.sels.forEach((e) => {
        text + e.coupon_code;
      });
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.$message(
        {
          message: "复制成功",
          type: "success",
        },
        2000
      );
    },
    GetNum() {
      this.$axios
        .get("/user/commoditycoupon/queryManagerListPage")
        .then((res) => {
          this.zonglan_right[0].number = res.data.data.total;
        });
    },

    GetList() {
      let url = "/user/commoditycoupon/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: this.couponStatus,
            pageSize: this.pageSize,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        });
    },
    changeCoupon(v){
      this.couponStatus = v
      this.GetList()
    },
    getCouType(query) {
      let url = "/user/commoditycouponType/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
          },
        })
        .then((res) => {
          this.cou_type_list = res.data.data.rows;
        });
    },
    // getCouCate(query) {
    //   let url = "/user/mechanismCategory/queryListPageChild";
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         status: 2,
    //         type: 1,
    //         pageSize: 100,
    //         source: "机构"
    //       }
    //     })
    //     .then(res => {
    //       this.cou_cate_list = res.data.data;
    //     });
    // },
    deleteCoupon(row) {
      let url = "/user/commoditycoupon/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "删除成功!" });
            this.GetList();
          } else {
            this.$message({ type: "danger", message: res.data.message });
          }
        });
    },

    selectiondrawCode(selection) {
      //  console.log(selection,"当列数据111")
      this.sels = selection;
    },

    filterChange(filterObj) {
      //  console.log(filterObj, "当列数据");
    },
    handleCurrentChange(val) {
      //  console.log(`当前页: `, val);
      this.currentPage = val;
      this.GetList();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDialog = false;
          this.updateDialog = false;
          this.form = {};
          this.temp = {};
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style></style>
